<template>
  <card class="relative">
    <img
      v-if="imgWidth && imgHeight"
      :src="img"
      class="absolute"
      :style="imgStyles"
    />
    <div style="pr-20">
      <h6 class="font-bold text-md md:text-2xl">{{title}}</h6>
      <slot></slot>
    </div>
  </card>
</template>

<script>
import Card from './Card'
export default {
  components: {
    Card
  },
  props: {
    title: {
      String,
      required: false
    },
    img: {
      type: String,
      required: false
    },
    width: {
      type: Number,
      default: 70
    },
    mobileWidth: {
      type: Number,
      default: 60
    },
    rightOffset: {
      type: Number,
      default: -0.3
    },
    mobilerightOffset: {
      type: Number,
      default: -0.2
    },
    topOffset: {
      type: Number,
      default: 0.3
    },
    mobileTopOffset: {
      type: Number,
      default: 0.3
    }
    // height: {
    //   type: String,
    //   default: 'auto'
    // },
    // mobileHeight: {
    //   type: String,
    //   default: 'auto'
    // }
  },
  data () {
    return {
      originalWidth: null,
      originalHeight: null,
      imgHeight: null
    }
  },
  mounted () {
    const img = new Image()
    img.onload = () => {
      this.originalWidth = img.width
      this.originalHeight = img.height
      this.imgHeight = Math.round((this.imgWidth / this.originalWidth) * this.originalHeight)
    }
    img.src = this.img
  },
  computed: {
    imgTop () {
      return (this.isMobile ? this.imgHeight * this.mobileTopOffset : this.imgHeight * this.topOffset) * -1
    },
    imgright () {
      return (this.isMobile ? this.imgWidth * this.mobilerightOffset : this.imgWidth * this.rightOffset) * -1
    },
    imgWidth () {
      return this.isMobile ? this.mobileWidth : this.width
    },
    imgStyles () {
      return {
        width: this.imgWidth + 'px',
        height: this.imgHeight + 'px',
        top: `${this.imgTop}px`,
        right: `${this.imgright}px`
      }
    },
    marginright () {
      return `calc(${this.imgWidth}px + ${this.imgright}px${this.isMobile ? ' - 1.8rem' : ''})`
    },
    // titleStyles () {
    //   if (this.isMobile) return { marginright: this.marginright }
    //   return ''
    // },
    containerStyles () {
      if (!this.isMobile) return { paddingRight: this.marginright }
      return ''
    }
  }
}
</script>

<style>
</style>
